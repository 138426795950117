import fetch from '../client-fetch';
import { signature } from '../sign';
import platform from '@/utils/platform';
import { getUrlParams } from '@/utils/common';

const BASE_PARAMS = () => {
  return {
    request_source: 'h5',
    proj: 'kuaiyin_h5',
    platform: platform.platform,
  };
};

const baseParams = getUrlParams(window.location.href) || {};

const TaskPost = (url, params) => {
  const BaseURL = process.env.VUE_APP_TASK_BASE_URL + url;
  let data = { ...BASE_PARAMS(), ...params };

  return fetch.post(BaseURL, signature(data, 'TASK_REQUEST'),{
    headers: {
      'client-v': baseParams.client_v || '',
      'device-id': baseParams.device_id || '',
      'platform': baseParams.platform || platform.platform || '',
      'utm-source': baseParams.utm_source || '',
      'platform-brand': baseParams.platform_brand || '',
    }
  });
};

export default TaskPost;
