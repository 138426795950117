/**
 * @description: 获取当前设备操作系统信息
 */

class Platform {
  constructor() {
    this.platform = null;
    this.ua = window.navigator.userAgent;
    this.$_init();
  }

  /**
   * @description: 初始化平台信息
   * @return {object}
   */

  $_init() {
    this.platform = {
      iOS: /iPad|iPhone|iPod/.test(this.ua) && !window.MSStream,
      Android: /android/i.test(this.ua), //
      windowsPhone: /IEMobile/i.test(this.ua),
      WeiXin: /micromessenger/i.test(this.ua), // 微信 客户端
      AliPay: /alipayclient/i.test(this.ua), // 支付宝 客户端
      QQ: this.$_isQQClient(), // qq 客户端
    };
    this.platform.isMobile = this.platform.iOS || this.platform.Android || this.platform.windowsPhone;
    this.platform.isPC = !this.platform.isMobile;
    this.platform.platform = this.$_getCurrentPlatform();
  }

  /**
   * @description: 判断是否在 QQ 移动端平台
   */

  $_isQQClient() {
    const isAppleQQ = /iPad|iPhone|iPod/.test(this.ua) && !window.MSStream && /\sQQ/i.test(this.ua);
    const isAndroidQQ =
      /(Android)/i.test(this.ua) && /MQQBrowser/i.test(this.ua) && /\sQQ/i.test(this.ua.split('MQQBrowser'));
    return isAppleQQ || isAndroidQQ;
  }

  /**
   * @description: 获取当前设备的平台信息
   * @return {string}
   */

  $_getCurrentPlatform() {
    const { platform } = this;

    if (platform.iOS) {
      return 'ios';
    } else if (platform.Android) {
      return 'Android';
    } else if (platform.windowsPhone) {
      return 'windowsPhone';
    }

    return 'PC';
  }
}

const platform = new Platform();

export default platform.platform;
