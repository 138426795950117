import Modal from './Modal';
import WithoutData from './WithoutData';
import Lottery2 from './Lottery/index2.vue';

const CommonComponents = {
  install(Vue) {
    Vue.component('Modal', Modal);
    Vue.component('WithoutData', WithoutData);
    Vue.component('Lottery2', Lottery2);
  },
};
export default CommonComponents;
