import Vue from 'vue';
import VueRouter from 'vue-router';
import TaskRoutes from './modules/task-routes';
import ActivityRoutes from './modules/activity-routes';
import CommonRoutes from './modules/common';
import AppRoutes from './modules/app';

Vue.use(VueRouter);

export function createRouter() {
  return new VueRouter({
    mode: 'history',
    fallback: false,
    base: process.env.BASE_URL,
    routes: [...TaskRoutes, ...ActivityRoutes, ...CommonRoutes, ...AppRoutes],
  });
}
