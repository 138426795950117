class Turntable {
  constructor(canvasBox, prizes, radius, textY, imgY, textSize, imgSize) {
    // 声明转盘相关属性
    const r = this.unitConversion(radius);
    this.prizes = prizes;
    this.textY = textY;
    this.imgY = imgY;
    this.textSize = textSize;
    this.imgSize = imgSize;
    this.x = r;
    this.y = r;
    this.radius = r;
    this.angelUnit = null;
    this.startAngle = null;

    // 生成转盘 canvas 元素
    const targetEle = document.createElement('canvas');
    targetEle.id = 'lottery';
    targetEle.width = r * 2;
    targetEle.height = r * 2;
    targetEle.style.transform = `rotate(0) scale(${1 / window.devicePixelRatio})`;
    targetEle.style.transition = 'transform 5s';

    // 获取 canvas 容器
    this.canvasBoxEle = document.querySelector(canvasBox);
    this.canvasBoxEle.appendChild(targetEle);

    // 获取转盘 canvas 画布
    this.ctx = targetEle.getContext('2d');
  }

  init() {
    const prizeNum = this.prizes.length;
    this.angelUnit = 360 / prizeNum;
    const k = Math.PI / 180;
    // 开始角度
    this.startAngle = (this.angelUnit * 1) / 2;
    // 弧度起点
    let startRadian = this.startAngle * k;
    // 创建奖品 canvas 容器
    const prizeCanvasBox = document.createElement('main');
    prizeCanvasBox.id = 'prize-canvas-box';
    prizeCanvasBox.style.position = 'absolute';
    prizeCanvasBox.style.top = 0;
    prizeCanvasBox.style.display = 'block';
    prizeCanvasBox.style.width = `${this.radius * 2}px`;
    prizeCanvasBox.style.height = `${this.radius * 2}px`;
    prizeCanvasBox.style.transform = `rotate(0) scale(${1 / window.devicePixelRatio})`;
    prizeCanvasBox.style.transition = 'transform 5s';

    this.prizes.forEach((item, i) => {
      const endAngle = (i + 1) * this.angelUnit + this.startAngle; // 结束角度
      const endRadian = endAngle * k; // 弧度终点

      // 求每个扇形2/3半径圆边中点坐标
      const middleAngle = (i + 1 / 2) * this.angelUnit + this.startAngle;
      const middleRadian = (90 - middleAngle) * k;
      const x = this.x,
        y = this.y,
        r = this.radius;
      item.x1 = x + ((r * 2) / 3) * Math.cos(middleRadian);
      item.y1 = y + ((r * 2) / 3) * Math.sin(middleRadian);

      // 绘制转盘
      this.sector(startRadian, endRadian, item);
      startRadian = endRadian;

      // 绘制奖品
      this.prizePaint(item, i, prizeCanvasBox);
    });
  }

  // 绘制扇形区背景
  sector(startRadian, endRadian, draw) {
    const ctx = this.ctx;

    // 当存在gradient属性，其类型为数组时 使用渐变填充
    if (draw.gradient && draw.gradient instanceof Array && draw.gradient.length) {
      const k = Math.PI / 180;
      const radianUnit = this.angelUnit * k;
      // 扇区中心线与x轴的夹角
      const endCenterRadian = endRadian - radianUnit / 2;

      const gradient = ctx.createLinearGradient(
        this.x,
        this.y,
        this.x + Math.cos(endCenterRadian) * this.radius,
        this.y + Math.sin(endCenterRadian) * this.radius
      );

      // 平均分配渐变色占用区域
      const percent = 1 / (draw.gradient.length - 1);
      draw.gradient.forEach((item, index) => {
        if (item.color && item.ratio) {
          // 颜色数组元素为对象且有 {color: 'xxx', ratio: 0.5} 的结构, 指定渐变比例
          gradient.addColorStop(item.ratio, item.color);
        } else if (typeof item === 'string') {
          // 颜色数组元素为普遍字符串, 平均分配渐变比例
          gradient.addColorStop(index * percent, item);
        }
      });
      ctx.fillStyle = gradient;
    } else {
      // 设置填充颜色
      ctx.fillStyle = draw.color;
    }

    // 开启路径
    ctx.beginPath();
    // 将绘制起点移动到圆心
    ctx.moveTo(this.x, this.y);
    // 绘制扇形
    ctx.arc(this.x, this.y, this.radius, startRadian, endRadian);
    // 关闭路径
    ctx.closePath();
    // 填充
    ctx.fill();
  }

  // 绘制奖品区内容
  prizePaint(draw, i, prizeCanvasBox) {
    // 创建奖品 canvas 元素
    const canvas = document.createElement('canvas');

    // 根据扇形角度动态计算 canvas 元素宽高
    canvas.width = (2 / 3) * this.radius;
    canvas.height = 2 * this.radius * Math.sin(((1 / 2) * this.angelUnit * Math.PI) / 180);

    // 设置 canvas 顶点坐标
    canvas.style.position = 'absolute';
    canvas.style.top = `${draw.x1}px`;
    canvas.style.left = `${draw.y1}px`;
    canvas.style.zIndex = 999999;
    canvas.classList.add('prizeCanvas');

    // 平移和旋转 canvas 元素
    const rotateAngle = this.startAngle + (i + 1 / 2) * this.angelUnit + 180;
    canvas.style.transform = `translate(-50%, -50%) rotate(${rotateAngle}deg)`;

    // 将每个 canvas 元素添加到奖品容器中
    prizeCanvasBox.appendChild(canvas);

    // 将奖品 canvas 容器添加到抽奖容器中
    this.canvasBoxEle.appendChild(prizeCanvasBox);

    // 获取奖品 canvas 画布
    const ctx1 = canvas.getContext('2d');

    // 将画布进行旋转
    ctx1.rotate((-90 * Math.PI) / 180);

    // 设置绘制文字参数
    // safari canvas不支持rem单位
    // ctx1.font = `${0.1 * window.devicePixelRatio}rem Arial`;
    ctx1.font = `600 ${this.unitConversion(this.textSize)}px PingFangSC-Semibold, PingFang SC`;
    ctx1.fillStyle = draw.gradient && draw.gradient instanceof Array && draw.gradient.length ? '#fff' : '#fa9065';

    // 绘制文字
    const textWidth = ctx1.measureText(draw.desc).width;
    const textPoint = { x: -(canvas.height + textWidth) / 2, y: this.unitConversion(this.textY) };
    ctx1.fillText(draw.desc, textPoint.x, textPoint.y);

    // 绘制奖品图标
    const prizeImg = new Image();
    const that = this;

    prizeImg.onload = function (e) {
      const _w = that.unitConversion(that.imgSize);
      ctx1.drawImage(prizeImg, -(canvas.height + _w) / 2, that.unitConversion(that.imgY), _w, _w);
    };
    prizeImg.src = draw.pic;
  }

  // 单位转化方法
  unitConversion(value) {
    const doc = window.document;
    const windowWidth = doc.documentElement.clientWidth || doc.body.clientWidth || window.innerWidth;

    if (windowWidth) {
      value = (value * windowWidth) / 375;
    }

    return value * window.devicePixelRatio;
  }
}

export default Turntable;
