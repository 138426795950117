import SHARE_LANDING_API from '@/api/modules/Fission/share-landing';
import TASK_COMMON_API from '@/api/modules/Task/common';
import LEARN_MONEY_API from '@/api/modules/Fission/learn-money';
import SEVENTH_NIGHT_API from '@/api/modules/Activity/seventh-night';
import ALBUM_API from '@/api/modules/Activity/ablum';
import COMPOSITE_VIDEO from '@/api/modules/Fission/composite-video';
import AI_TUNING from './modules/Activity/ai-tuning';
import ACTIVITY_TEMPLATE from './modules/Activity/activity-template';
import REDIRECT_LANDING from './modules/Fission/redirect-landing';
import MID_AUTUMN_API from './modules/Activity/mid-autumn';
import NATIONAL_DAY_API from './modules/Activity/national-day';
import USER_GET_COMPLIANCE from './modules/App/personal-info-collect';
import KY_API from './modules/App/ky';

const API = {
  ...SHARE_LANDING_API,
  ...TASK_COMMON_API,
  ...LEARN_MONEY_API,
  ...SEVENTH_NIGHT_API,
  ...ALBUM_API,
  ...COMPOSITE_VIDEO,
  ...AI_TUNING,
  ...REDIRECT_LANDING,
  ...ACTIVITY_TEMPLATE,
  ...MID_AUTUMN_API,
  ...NATIONAL_DAY_API,
  ...USER_GET_COMPLIANCE,
  ...KY_API,
};

export default API;
