/*
 * @description: IOS 客户端，可供 H5 调用方法定义
 */

const IOS_BRIDGE_METHOD = {
  IS_CALENDAR_ENABLED: 'isCalendarEnabled',
  HANDLE_CALENDAR: 'handleCalendar',
  OPEN_PAGE_WITH_JSON: 'openPageWithJSON',
  TOAST: 'toast',
  COPY: 'copy',
  SHARE_TO: 'shareInfo',
  SET_TITLE: 'setTitle',
  CLOSE_WEBVIEW: 'closeWebView',
  GET_USER_INFO: 'getUserInfo',
  SHOW_REWARD_VIDEO_AD_WITH_JSON: 'showRewardVideoAdWithJSON',
  INIT_MUSIC_PLAYER_LIST: 'setMusicList',
  APPEND_MUSIC_PLAYER_LIST: 'appendMusicList',
  HANDLE_MUSIC_BY_ACTION: 'h5MusicAction',
  UPLOAD_OPUS: 'uploadOpus',
  IS_NOTIFICATION_ENABLED: 'isNotificationEnabled',
  OPEN_NOTIFICATION: 'openNotification',
  PAUSE_MUSIC: 'pauseMusic',
  SIGN_CONTENT: 'signContent',
  OPEN_NATIVE_PAGE: 'openNativePage',
  LAUNCH_APP_DETAILS_SETTINGS: 'launchAppDetailsSettings',
  REFRESH_TOKEN: 'refreshToken',
  BROWSER: 'browser',
  LIVE_RECHARGE: 'liveRecharge',
  lOAD_TT_FEED: 'loadTTFeed',
  CLOSE_TT_FEED: 'closeTTFeed',
};

export default IOS_BRIDGE_METHOD;
