import Vue from 'vue';
import 'es6-promise/auto';
import { createApp } from './app';
import clientInstall from '@/main/client-install';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
const sentryConfig = require('../sentry.config');
// 集成 Sentry 监控
process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    Vue,
    dsn: 'https://5ef408537f444ad19186a5d347713ae4@sentry.kaixinyf.cn/5',
    environment: `${sentryConfig.ENVIRONMENT}`,
    integrations: [new Integrations.BrowserTracing({ tracingOrigins: ['h5.kaixinyf.cn', 'h5.rd.kaixinyf.cn'] })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    release: `${sentryConfig.PROJECT_NAME}-${sentryConfig.ENVIRONMENT}-${sentryConfig.RELEASE_VERSION}`,
    logErrors: true,
  });

Vue.use(clientInstall);
// a global mixin that calls `asyncData` when a route component's params change
Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    const { asyncData } = this.$options;
    if (asyncData) {
      asyncData({
        store: this.$store,
        route: to,
      })
        .then(next)
        .catch(next);
    } else {
      next();
    }
  },
});

!(function e() {
  const t = document.documentElement,
    n = document.documentElement.clientWidth / 7.5;
  t.style.fontSize = n + 'px';
  const o = parseFloat(window.getComputedStyle(t).fontSize);
  if (n !== o) {
    const i = n * (n / o);
    t.style.fontSize = i + 'px';
  }
  window.addEventListener('resize', e);
})();

const { app, router, store } = createApp();

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}
// if (store.state.needRedirect) {
//   const { url } = store.state.needRedirect;
//
//   window.location.replace(url);
// }
// wait until router has resolved all async before hooks
// and async components...
router.onReady(() => {
  // Add router hook for handling asyncData.
  // Doing it after initial route is resolved so that we don't double-fetch
  // the data that we already have. Using router.beforeResolve() so that all
  // async components are resolved.
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);
    let diffed = false;
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = prevMatched[i] !== c);
    });
    const asyncDataHooks = activated.map((c) => c.asyncData).filter((_) => _);

    if (!asyncDataHooks.length) {
      return next();
    }

    Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to })))
      .then(() => {
        next();
      })
      .catch(next);
  });

  // actually mount to DOM
  app.$mount('#app', true);
});
