import seventhNight from './modules/seventh-night';
import album from './modules/album';
import aiTuning from './modules/ai-tuning';
import activityTemplate from './modules/activity-template';
import midAutumn from './modules/mid-autumn';
import national from './modules/national';

export default {
  namespaced: true,
  state: () => ({}),
  mutation: {},
  actions: {},
  getters: {},
  modules: {
    seventhNight,
    album,
    aiTuning,
    activityTemplate,
    midAutumn,
    national,
  },
};
