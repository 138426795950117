export default {
  namespaced: true,
  state: () => ({
    redirectUrl: null,
    quickInfo: {},
  }),
  mutations: {
    SET_REDIRECT_URL(state, data) {
      state.redirectUrl = data;
    },
    SET_QUICK_INFO(state, data) {
      state.quickInfo = data;
    },
  },
  getters: {
    RedirectUrl(state) {
      return state.redirectUrl;
    },
    QuickInfo(state) {
      return state.quickInfo;
    },

  },
};
