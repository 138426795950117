<template>
  <div class="without-data">
    <img class="without-data__img" :src="imgSrc" alt="无数据" />
    <p class="without-data__text">{{ text }}</p>
  </div>
</template>

<script>
  export default {
    name: 'WithoutData',
    props: {
      imgSrc: {
        type: String,
        default: '@/assets/images/common/404.png',
      },
      text: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style scoped lang="less">
  .without-data {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__img {
      width: 2.5rem;
      height: 2.5rem;
    }
    &__text {
      margin-top: 0.2rem;
      width: 100%;
      line-height: 0.4rem;
      text-align: center;
      font-size: 0.28rem;
      color: #a6a6a6;
    }
  }
</style>
