export default [
  {
    path: '/c3bc45',
    name: 'Share',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/57c6ae3',
    name: '57c6ae3',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/ef9162',
    name: 'ef9162',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/c2s6a32',
    name: 'c2s6a32',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/3ff8f0',
    name: '3ff8f0',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/c4f0cc',
    name: 'c4f0cc',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/b2c0zc',
    name: '/b2c0zc',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/qzc0ff',
    name: '/qzc0ff',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/e30aze',
    name: '/e30aze',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/0x0c09',
    name: '/0x0c09',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/0x0c08',
    name: '/0x0c08',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/1x2z02',
    name: '/1x2z02',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/3x3z03',
    name: '/3x3z03',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/4x4z04',
    name: '/4x4z04',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/5x5z05',
    name: '/5x5z05',
    component: () => import('@/views/Welfare/Fission/ShareLanding/index.vue'),
  },
  {
    path: '/share-landing-music',
    name: 'ShareLandingMusic',
    component: (resolve) => require(['@/views/Welfare/Fission/ContentLanding/Music.vue'], resolve),
  },
  {
    path: '/share-landing-music-v2',
    name: 'ShareLandingMusicV2',
    component: (resolve) => require(['@/views/Welfare/Fission/ContentLanding/MusicV2.vue'], resolve),
  },
  {
    path: '/share/video',
    name: 'ShareLandingVideo',
    component: (resolve) => require(['@/views/Welfare/Fission/ContentLanding/Video.vue'], resolve),
  },
  {
    path: '/learn-money-share',
    name: 'learn-money-share',
    component: () => import('@/views/Welfare/Fission/LearnMoney/index'),
  },
  {
    path: '/double-punch-landing',
    name: 'double-punch-landing',
    component: () => import('@/views/Welfare/Fission/DoublePunch/index'),
  },
  {
    path: '/share-composite-video',
    name: 'share-composite-video',
    component: () => import('@/views/Welfare/Fission/CompositeVideoLanding/Video'),
  },
  {
    path: '/share-redirect',
    name: 'share-redirect',
    component: () => import('@/views/Welfare/Fission/LandingRedirect/index'),
  },
  {
    path: '/sh4re-redirect',
    name: 'sh4re-redirect',
    component: () => import('@/views/Welfare/Fission/LandingRedirect/index'),
  },
  {
    path: '/together-watch',
    name: 'together-watch',
    component: () => import('@/views/Welfare/Fission/TogetherWatch/index'),
  },
];
