export default {
  namespaced: true,
  state: () => ({
    provinces: [],
    province: {
      notice: '当前四川省助力值第一名',
      province_info: {
        background: 'https://static1.kaixinyf.cn/img/lza6327e34b9873f313693521.jpg',
      },
      music_list: [
        {
          cover: 'https://static1.kaixinyf.cn/img/lza620f687532b2a855259816.png',
          play_url: 'https://static1.kaixinyf.cn/resource/b36e5e21399f4d65a10f8ce67a2ec0db.aac',
        },
        {
          cover:
            'http://thirdwx.qlogo.cn/mmopen/vi_32/TphUu0cQDLGhSKrUCgXicae9hYpNmS19eVKqWTRpYKQHAEw03wh21rNPXa2RPFOEhCYV3OVUFZmeuW3E2J1Mmtg/0',
          play_url: 'https://static1.kaixinyf.cn/resource/38b0ec55a71a4f6dbc9128934400f5ee.aac',
        },
        {
          cover:
            'http://thirdwx.qlogo.cn/mmopen/vi_32/lvTuNlVnt6PYg2QuHsDN6wQ4GwUgUfyiac8mDq9icuqxvm45qr23mB58YaBUnUzJW3zSZq8413A14jvqicoYUva9A/0',
          play_url: 'https://static1.kaixinyf.cn/resource/0a7c201618cb491e924004ae0a7dce0c.mp3',
        },
        {
          cover:
            'http://thirdwx.qlogo.cn/mmopen/vi_32/4TWovVJd1SUOpMD9EDoS2nUxkg3s3g3ibmNiaE8ESvBRHiaZ1KYKWhdibeDr1mGyP1J610PXg39ZbPKclfG6wicyicnQ/0',
          play_url: 'https://static1.kaixinyf.cn/resource/73e9dfeea4784f70b2dbb8d6cdff2c5e.mp3',
        },
        {
          cover:
            'http://thirdwx.qlogo.cn/mmopen/vi_32/lvTuNlVnt6PYg2QuHsDN6wQ4GwUgUfyiac8mDq9icuqxvm45qr23mB58YaBUnUzJW3zSZq8413A14jvqicoYUva9A/0',
          play_url: 'https://static1.kaixinyf.cn/resource/207b28f0109847f2a8cb8d08146b32b7.mp3',
        },
        {
          cover:
            'http://thirdwx.qlogo.cn/mmopen/vi_32/X5henBGwS6pEnyGV2icJqKmicSmkK2liaqx7pXBT5hvMT71EYry5IWynBf9F1L5OgvYa7ZNZwRwFic93cAhtWl3mrw/0',
          play_url: 'https://static1.kaixinyf.cn/resource/ecc29fb10b0d434a9a4c32a0cebad3f9.mp3',
        },
      ],
    },
  }),
  mutations: {
    SET_NATIONAL_PAGE_INFO(state, data) {
      state.provinces = data;
    },
    SET_NATIONAL_PROVINCE_INFO(state, data) {
      state.province = data;
    },
  },
  getters: {
    Provinces(state) {
      return state.provinces;
    },
    Province(state) {
      return state.province;
    },
  },
};
