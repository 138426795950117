/**
 * @description Date 对象增加格式化方法
 * @param {string} fmt - 格式
 * @returns
 */

Date.prototype.format = function (fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
};

/**
 * @description: 格式化播放时间
 * @param time {string} 输入时间
 * @param type {string} 输入类型
 * @return {string} 输出时间
 */

export function formatTime(time, type = 'm:s') {
  const t = +time;
  let d = Math.floor(t / 86400);
  let h = Math.floor((t - d * 86400) / 3600);
  let m = Math.floor((t - d * 86400 - h * 3600) / 60);
  let s = Math.floor(t - d * 86400 - h * 3600 - m * 60);
  d = d ? `${d}天` : '';
  h = h > 9 ? h : `0${h}`;
  m = m > 9 ? m : `0${m}`;
  s = s > 9 ? s : `0${s}`;

  switch (type) {
    case 'm:s':
      return `${m}:${s}`;
    case 'h:m:s':
      return `${h}:${m}:${s}`;
    case 'd:h:m:s':
      return `${d}:${h}:${m}:${s}`;
  }
}
/**
 * @description: 不足两位前面补零
 * @param num {String} 输入时间
 * @return {string} 输出时间
 */

export function twoByte(num) {
  num = String(num);
  if (num.length < 2) {
    return '0' + num;
  }
  return num;
}

/**
 * 生成 n-m 之间的一个随机数
 */

export function randomNum(minNum, maxNum) {
  let result = 0;

  switch (arguments.length) {
    case 1:
      result = parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      result = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      result = 0;
      break;
  }

  return result;
}

/**
 * @description: 转换播放量
 * @param {Number} num - 播放量
 * @param {Number} point - 分位
 */

export function tranNumber(num, point) {
  // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
  let numStr = num.toString().split('.')[0];
  if (numStr.length < 5) {
    // 判断数字有多长,如果小于5,表示1万以内的数字,让其直接显示
    return numStr;
  } else if (numStr.length >= 5 && numStr.length <= 8) {
    // 如果数字大于5位,小于8位,让其数字后面加单位万
    let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point);

    // 由千位,百位组成的一个数字
    return parseFloat(parseInt(num / 10000) + '.' + decimal) + '万';
  } else if (numStr.length > 8) {
    // 如果数字大于8位,让其数字后面加单位亿
    let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);

    return parseFloat(parseInt(num / 100000000) + '.' + decimal) + '亿';
  }
}

/**
 * @description: 生成 UUID
 * @return {String} uuid
 */

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * 防抖函数，返回函数连续调用时，空闲时间必须大于或等于 wait，func 才会执行
 *
 * @param  {function} func        回调函数
 * @param  {number}   wait        表示时间窗口的间隔
 * @param  {boolean}  immediate   设置为 true 时，是否立即调用函数
 * @return {function}             返回客户调用函数
 */
export function debounce(func, wait, immediate) {
  let timer, context, args;
  // 延迟执行函数
  const later = () =>
    setTimeout(() => {
      // 延迟函数执行完毕，清空缓存的定时器序号
      timer = null;
      // 延迟执行的情况下，函数会在延迟函数中执行
      // 使用到之前缓存的参数和上下文
      if (!immediate) {
        func.apply(context, args);
        context = args = null;
      }
    }, wait);

  // 这里返回的函数是每次实际调用的函数
  return function (...params) {
    // 如果没有创建延迟执行函数（later），就创建一个
    if (!timer) {
      timer = later();
      // 如果是立即执行，调用函数
      // 否则缓存参数和调用上下文
      if (immediate) {
        func.apply(this, params);
      } else {
        context = this;
        args = params;
      }
      // 如果已有延迟执行函数（later），调用的时候清除原来的并重新设定一个
      // 这样做延迟函数会重新计时
    } else {
      clearTimeout(timer);
      timer = later();
    }
  };
}

/**
 * @description 解析 url 参数
 * @param { String } url 网页全地址
 * @param { String } name 参数名称
 * */
export function getUrlParams(url, name) {
  if (url) {
    const result = {};
    let search = url.split('?')[1];
    search = search ? search.split('&') : [];

    for (let param of search) {
      const maps = param.split('=');
      result[maps[0]] = maps[1];
    }

    if (name) return result[name];

    return result;
  }
}
