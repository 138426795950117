<template>
  <div id="lottery-box">
    <slot></slot>
  </div>
</template>

<script>
  import Turntable from './turntable2';

  export default {
    data() {
      return {
        turntable: {},
        lotteryEle: null,
        prizeEle: null,
        winIndex: null,
        lastWinIndex: null,
        winAngle: null,
        lastAngle: null,
        pre: null, // 上一次点击抽奖按钮时间戳
      };
    },
    props: {
      prizes: {
        type: Array,
        default: () => {
          return [];
        },
      },
      radius: {
        type: Number,
        default: 150,
      },
      textY: {
        type: Number,
        default: 50,
      },
      imgY: {
        type: Number,
        default: 55,
      },
      textSize: {
        type: Number,
        default: 10,
      },
      imgSize: {
        type: Number,
        default: 42,
      },
    },
    created() {
      this.$nextTick(() => {
        this.turntable = new Turntable(
          '#lottery-box',
          this.prizes,
          this.radius,
          this.textY,
          this.imgY,
          this.textSize,
          this.imgSize
        );

        this.turntable.init();
      });
    },
    methods: {
      // 转盘抽奖
      handleLottery(result) {
        const winPrize = this.prizes.filter((item, index) => {
          if (item.id === String(result.hit_draw_prize_id)) {
            this.winIndex = index + 1;
            return item;
          }
        });

        if (winPrize.length) {
          // 计算中奖目标的随机角度
          const angleUnit = this.turntable.angelUnit;

          if (!this.lastWinIndex) {
            // const offset = 270 - this.winIndex * angleUnit + this.turntable.startAngle - angleUnit * Math.random();
            const offset = 270 - this.winIndex * angleUnit;
            this.winAngle = offset + 6 * 360;
          } else {
            const offset = this.lastAngle - (this.winIndex - this.lastWinIndex) * angleUnit;
            this.winAngle = offset + 7 * 360;
          }

          this.lastWinIndex = this.winIndex;
          this.lastAngle = this.winAngle;

          // 转动转盘
          if (!this.prizeEle) {
            this.prizeEle = document.querySelector('#prize-canvas-box');
          }

          const emitLotteryEnd = () => {
            this.$emit('lotteryEnd', result);
            this.lotteryEle.removeEventListener('transitionend', emitLotteryEnd, false);
            this.lotteryEle = null;
          };

          if (!this.lotteryEle) {
            this.lotteryEle = document.querySelector('#lottery');

            // 绑定抽奖结束的监听
            this.lotteryEle.addEventListener('transitionend', emitLotteryEnd, false);
          }
          this.prizeEle.style.transform = `rotate(${this.winAngle}deg) scale(${1 / window.devicePixelRatio})`;
          this.lotteryEle.style.transform = `rotate(${this.winAngle}deg) scale(${1 / window.devicePixelRatio})`;
        } else {
          console.log('没有匹配的奖品id');
        }
      },

      // 对抽奖按钮点击事件进行节流
      throttle(prizeId) {
        let now = Date.now();

        if (!this.pre || now - this.pre > 5000) {
          this.handleLottery(prizeId);
          this.pre = Date.now();
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  #lottery-box {
    position: absolute;
    top: 50%;
    left: 50.7%;
    transform: translate(-50%, -50%);
  }
</style>
