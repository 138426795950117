import Recorder from 'recorder-core';
import 'recorder-core/src/engine/wav';

export const getUserMedia = function (constraints) {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // 首先，如果有getUserMedia的话，就获得它
      const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

      // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
      }

      // 否则，为老的navigator.getUserMedia方法包裹一个Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }

  return navigator.mediaDevices.getUserMedia(constraints);
};

let rec, recBlob;

/** 调用open打开录音请求好录音权限 **/
export const recOpen = function () {
  rec = null;
  recBlob = null;

  const newRec = Recorder({
    //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
    type: 'wav',
    sampleRate: 16000,
    bitRate: 16,
    onProcess: function (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) {
      //录音实时回调，大约1秒调用12次本回调
      /*document.querySelector(".recpowerx").style.width = powerLevel+"%";
      document.querySelector(".recpowert").innerText = bufferDuration+" / "+powerLevel;

      //可视化图形绘制
      wave.input(buffers[buffers.length-1], powerLevel, bufferSampleRate);*/
    },
  });

  // createDelayDialog(); //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
  //打开麦克风授权获得相关资源
  newRec.open(
    function () {
      // dialogCancel(); //如果开启了弹框，此处需要取消

      rec = newRec;

      //此处创建这些音频可视化图形绘制浏览器支持妥妥的
      // wave = Recorder.FrequencyHistogramView({elem:".recwave"});

      // reclog("已打开录音，可以点击录制开始录音了",2);
    },
    function (msg, isUserNotAllow) {
      //用户拒绝未授权或不支持
      // dialogCancel(); //如果开启了弹框，此处需要取消
      // reclog((isUserNotAllow?"UserNotAllow，":"")+"打开录音失败："+msg,1);
    }
  );

  /* window.waitDialogClick = function(){
    dialogCancel();
    reclog("打开失败：权限请求被忽略，<span style='color:#f00'>用户主动点击的弹窗</span>",1);
  }; */
};

/** 关闭录音，释放资源 **/
export const recClose = function () {
  if (rec) {
    rec.close();
    // reclog("已关闭");
  } else {
    // reclog("未打开录音",1);
  }
};

/** 开始录音 **/
export const recStart = function () {
  //打开了录音后才能进行start、stop调用
  if (rec && Recorder.IsOpen()) {
    recBlob = null;
    rec.start();
    console.log('已开始录音...');
    // reclog("已开始录音...");
  } else {
    // reclog("未打开录音",1);
  }
};

/**暂停录音**/
export const recPause = function () {
  if (rec && Recorder.IsOpen()) {
    rec.pause();
  } else {
    // reclog("未打开录音",1);
  }
};
/**恢复录音**/
export const recResume = function () {
  if (rec && Recorder.IsOpen()) {
    rec.resume();
  } else {
    // reclog("未打开录音",1);
  }
};

/**
 * @description 结束录音，得到音频文件
 * */
export const recStop = function () {
  return new Promise((resolve, reject) => {
    if (!(rec && Recorder.IsOpen())) {
      console.log('未打开录音权限');
      reject({
        type: 'record',
        message: '未打开录音权限',
      });
    }

    rec.stop(
      function (blob, duration) {
        console.log(blob, (window.URL || window.webkitURL).createObjectURL(blob), '时长:' + duration + 'ms');
        recBlob = blob;
        resolve({
          blob,
          duration,
        });
      },
      function (msg) {
        console.log('录音失败:' + msg);
        reject({
          type: 'record',
          message: msg,
        });
      }
    );
  });
};

/**播放**/
export const recPlay = function () {
  if (!recBlob) {
    // reclog("请先录音，然后停止后再播放",1);
    console.log('请先录音，然后停止后再播放');
    return;
  }
  // var cls=("a"+Math.random()).replace(".","");
  // reclog('播放中: <span class="'+cls+'"></span>');
  var audio = document.createElement('audio');
  audio.controls = true;
  document.querySelector('#records').appendChild(audio);
  //简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
  audio.src = (window.URL || webkitURL).createObjectURL(recBlob);
  audio.play();

  setTimeout(function () {
    (window.URL || webkitURL).revokeObjectURL(audio.src);
  }, 5000);
};

/**上传**/
export const recUpload = function () {
  var blob = recBlob;
  if (!blob) {
    reclog('请先录音，然后停止后再上传', 1);
    return;
  }

  //本例子假设使用原始XMLHttpRequest请求方式，实际使用中自行调整为自己的请求方式
  //录音结束时拿到了blob文件对象，可以用FileReader读取出内容，或者用FormData上传
  var api = 'https://xx.xx/test_request';
  var onreadystatechange = function (title) {
    return function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          reclog(title + '上传成功', 2);
        } else {
          reclog(
            title +
              '没有完成上传，演示上传地址无需关注上传结果，只要浏览器控制台内Network面板内看到的请求数据结构是预期的就ok了。',
            '#d8c1a0'
          );

          console.error(title + '上传失败', xhr.status, xhr.responseText);
        }
      }
    };
  };
  reclog('开始上传到' + api + '，请求稍后...');

  /***方式一：将blob文件转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传***/
  var reader = new FileReader();
  reader.onloadend = function () {
    var postData = '';
    postData += 'mime=' + encodeURIComponent(blob.type); //告诉后端，这个录音是什么格式的，可能前后端都固定的mp3可以不用写
    postData += '&upfile_b64=' + encodeURIComponent((/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1]); //录音文件内容，后端进行base64解码成二进制
    //...其他表单参数

    var xhr = new XMLHttpRequest();
    xhr.open('POST', api);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = onreadystatechange('上传方式一【Base64】');
    xhr.send(postData);
  };
  reader.readAsDataURL(blob);

  /***方式二：使用FormData用multipart/form-data表单上传文件***/
  var form = new FormData();
  form.append('upfile', blob, 'recorder.mp3'); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
  //...其他表单参数

  var xhr = new XMLHttpRequest();
  xhr.open('POST', api);
  xhr.onreadystatechange = onreadystatechange('上传方式二【FormData】');
  xhr.send(form);
};

export default {
  recOpen,
  recStart,
  recPlay,
  recStop,
  Recorder,
};
