import IOS_LISTEN_METHOD from './interface';
import IOS_BRIDGE_METHOD from './method';
import dsBridge from 'dsbridge';

const USER_INFO = require('../../user-info.json');

/**
 * @description: IOS 桥接对象注册事件回调方法
 * @param {string} event - 时间名称
 * @param {object} callback - 回调函数
 * @return: void
 */

const IOS_REGISTER = (event, callback) => {
  try {
    dsBridge.register(event, callback);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: js 调用 window.dsbridge 原生方法
 * @param {string} functionName - 方法名
 * @param {string} params - 参数
 * @return: *
 */

const IOS_APP_CALL = (functionName, ...params) => {
  try {
    return dsBridge.call(functionName, ...params) || handleNoBridge(functionName, ...params);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: 处理本地开发，没有 window.window.bridge 的情况
 * @param {string} functionName - 方法名
 * @param {any} params - 参数
 */

const handleNoBridge = (functionName, params) => {
  switch (functionName) {
    case 'openPageWithJSON':
      window.location.href = JSON.parse(params).url;
      break;
    case 'getUserInfo':
      if (process.env.NODE_ENV === 'development') {
        return JSON.stringify(USER_INFO);
      }
      break;
    case 'setTitle':
      document.title = params;
      break;
    default:
    // throw Error('window.dsbridge is not defined!');
  }
};

export { IOS_REGISTER, IOS_APP_CALL, IOS_LISTEN_METHOD, IOS_BRIDGE_METHOD };
