/*
 * @description: 定义 iOS 客户端调用的接口
 */

const IOS_LISTEN_METHOD = {
  ON_SHOW: 'onShow',
  ON_HIDDEN: 'onHidden',
  ON_REWARD_VIDEO_PLAYED: 'onRewardVideoPlayEnd',
  ON_MUSIC_ACTION: 'onMuscicAction',
  ON_CALENDAR_CALLBACK: 'onCalendarCallBack',
  ON_RECHARGE_CALLBACK: 'onRechargeCallback',
};

export default IOS_LISTEN_METHOD;
