/**
 * @Description: Logger 类
 */
import CONFIG from './config';
import Platform from '../platform';
import API from '@/api';
import TaskPost from '@/utils/http/modules/http-task';
let client = { uid: '', sa_device_id: '', 'client-v': '' };

class Logger {
  constructor() {}

  log(opt) {
    const options = Object.assign(opt, { level: 'info' });
    this.$_combineOptions(options);
  }

  debug(opt) {
    const options = Object.assign(opt, { level: 'debug' });
    this.$_combineOptions(options);
  }

  warn(opt) {
    const options = Object.assign(opt, { level: 'warn' });
    this.$_combineOptions(options);
  }

  error(opt) {
    const options = Object.assign(opt, { level: 'error' });
    this.$_combineOptions(options);
  }

  $_combineOptions(options) {
    // console.log(`options =>`, options);
    const defaultOptions = {
      date: new Date().format('yyyy-MM-dd hh:mm:ss'),
      timestamp: Date.now(),
      level: '',
      eventType: '',
      errorType: '',
      stack: '',
      filename: '',
      project: CONFIG.projectName,
      biz: '',
      env: process.env.NODE_ENV,
      uid: client.uid,
      client,
      deviceId: client['sa_device_id'],
      url: window.location.href,
      referer: '',
      action: '',
      preAction: '',
      data: '',
      apiPath: '',
      apiSource: '',
      dataSend: '',
      userAgent: window.navigator.userAgent,
      system: Platform.platform,
      appVersion: client['client-v'],
    };

    let errorMessage = Object.assign(defaultOptions, options, {
      errorType: options.errorType || this.$_formatErrorType(options.message),
      eventType: options.errorType || options.eventName ? options.eventName : 'unTypedEvent',
    });

    const data = {
      event_name: options.eventName || 'unTypedError',
      params: JSON.stringify(errorMessage),
      content: JSON.stringify(errorMessage),
    };
    console.log(data);
    // this.$_sendException(data);
  }

  $_formatErrorType(message) {
    switch (true) {
      case /ReferenceError/i.test(message):
        return 'ReferenceError';
      case /SyntaxError/i.test(message):
        return 'SyntaxError';
      case /TypeError/i.test(message):
        return 'TypeError';
      case /URIError/i.test(message):
        return 'URIError';
      case /InternalError/i.test(message):
        return 'InternalError';
      case /RangeError/i.test(message):
        return 'RangeError';
      default:
        return 'Error';
    }
  }

  async $_sendException(data) {
    try {
      await TaskPost(API.EXCEPTION_LOG_API, data);
    } catch (e) {
      console.log(e);
    }
  }
}

const logger = new Logger();

export default logger;
