export default {
  namespaced: true,
  state: () => ({
    musicFeed: {},
    images: {},
  }),
  mutations: {
    SET_MUSIC_FEED(state, data) {
      state.musicFeed = data;
    },
    SET_IMAGES(state, data) {
      state.images = data;
    },
  },
  getters: {
    MUSIC_FEED(state) {
      return state.musicFeed;
    },
    IMAGES(state) {
      return state.images;
    },
  },
};
