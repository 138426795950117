export default {
  namespaced: true,
  state: () => ({
    pageInfo: null,
    userInfo: null,
    inviteCode: '',
    clipboardText: '',
    musicList: [],
    shufflingInfo: [],
    showMaxReward: 0,
    evaluation: [],
    inviteLandingAb: 'a',
    isAndroid: false,
  }),
  mutations: {
    SET_USER_INFO(state, playload) {
      state.userInfo = playload;
    },
    SET_INVITE_CODE(state, playload) {
      state.inviteCode = playload;
    },
    SET_CLIPBOARD_TEXT(state, playload) {
      state.clipboardText = playload;
    },
    SET_MUSIC_LIST(state, playload) {
      state.musicList = playload;
    },
    SET_SHUFFLING_INFO(state, playload) {
      state.shufflingInfo = playload;
    },
    SET_MAX_REWARD(state, playload) {
      state.showMaxReward = playload;
    },
    SET_EVALUATION(state, playload) {
      state.evaluation = playload;
    },
    SET_LANDING_AB(state, playload) {
      state.inviteLandingAb = playload;
    },
  },
  actions: {},
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    inviteCode(state) {
      return state.inviteCode;
    },
    clipboardText(state) {
      return state.clipboardText;
    },
    musicList(state) {
      return state.musicList;
    },
    shufflingInfo(state) {
      return state.shufflingInfo;
    },
    showMaxReward(state) {
      return state.showMaxReward;
    },
    evaluation(state) {
      return state.evaluation;
    },
    inviteLandingAb(state) {
      return state.inviteLandingAb;
    },
  },
};
