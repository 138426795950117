export default {
  namespaced: true,
  state: () => ({
    sharePageInfo: null,
  }),
  mutations: {
    SET_SHARE_PAGE_INFO(state, data) {
      state.sharePageInfo = data;
    },
  },
  getters: {
    shareArticleInfo(state) {
      return state.sharePageInfo;
    },
  },
};
