export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
  }),
  mutations: {
    SET_USER_INFO(state, data) {
      state.userInfo = data;
    },
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
  },
};
