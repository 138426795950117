import userInfo from './modules/user-info';
import ky from './modules/ky';

export default {
  namespaced: true,
  state: () => ({}),
  mutation: {},
  actions: {},
  getters: {},
  modules: {
    userInfo,
    ky,
  },
};
