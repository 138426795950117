import Axios from 'axios';
import Qs from 'qs';
import { Toast } from 'vant';
import logger from '@/utils/logger';

const axiosInstance = Axios.create({
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
  timeout: 15000,
  withCredentials: true,
  paramsSerializer(params) {
    return Qs.stringify(Object.assign({}, params));
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return handleOKHTTP(response);
  },
  (error) => {
    return handleErrorHTTP(error);
  }
);

/**
 * @description: http 响应成功拦截处理
 * @param response
 */

const handleOKHTTP = function (response) {
  const { data } = response;

  if (Number(data.code) === 0) {
    return data.data;
  }
  return handleException(response);
};

/**
 * @description: 业务错误处理
 * @param {object} response - 返回数据
 */

const handleException = function (response) {
  const {
    data: { code, action, message },
  } = response;

  logger.error({
    eventName: 'networkError',
    message,
    apiSource: JSON.stringify(response.data),
    apiPath: response.config.url,
    dataSend: response.config.data,
    apiCode: code,
  });

  switch (code) {
    case '10001': // 未登录
      break;
    case '10003': // 参数不正确
      Toast(`参数不正确`);
      break;
    case '10005': // access_token 已过期
      // SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '40001': // 签名 token 过期, 需要重新获取token
      // SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '20000': // 需要登录
      break;
    default:
      if (String(action) === 'toast') {
        if (message) {
          Toast(message);
        }
      }
  }

  return Promise.reject(response.data);
};

/**
 * @description: http 响应错误拦截处理
 * @param error
 * @return: {Promise}
 */

const handleErrorHTTP = function (error) {
  if (error.response) {
    logger.error({
      eventName: 'networkError',
      message: error.message,
      stack: error.stack,
      error,
      apiPath: error.config.url,
      dataSend: error.config.data,
    });
    switch (status) {
      case 404:
        throw Error('未找到请求资源！');
      case 500:
        throw Error('服务器异常');
    }
  } else {
    if (!new RegExp('/api/config/exception_log').test(error.config.url)) {
      logger.error({
        eventName: 'networkError',
        errorType: 'NoResponseError',
        message: error.message,
        stack: error.stack,
        error,
        apiPath: error.config.url,
        dataSend: error.config.data,
      });
    }
  }
  return Promise.reject(error);
};

export default axiosInstance;
