export default {
  namespaced: true,
  state: () => ({
    code_data: {},
  }),
  mutations: {
    SET_CODE_DATA(state, data) {
      state.code_data = data;
    },
  },
  getters: {
    code_data(state) {
      return state.code_data;
    },
  },
};
