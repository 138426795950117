export default {
  namespaced: true,
  state: () => ({
    albumInfo: {},
    musicList: [],
    playingMusic: null,
    sharePlaylistAb: 'a',
  }),
  mutations: {
    SET_ALBUM_INFO(state, data) {
      state.albumInfo = data;
    },
    SET_MUSIC_LIST(state, list) {
      list.forEach((item, index) => {
        item.index = index;
        item.isPlaying = false;
      });
      state.musicList = list;
    },
    SET_PLAYING_MUSIC(state, data) {
      state.playingMusic = data;
    },
    SET_SHARE_PLAYLIST_AB(state, playLoad) {
      state.sharePlaylistAb = playLoad;
    },
  },
  getters: {
    ALBUM(state) {
      return state.albumInfo;
    },
    MUSIC_LIST(state) {
      return state.musicList;
    },
    PLAYING_MUSIC(state) {
      return state.playingMusic;
    },
    SHARE_PLAYLIST_AB(state) {
      return state.sharePlaylistAb;
    },
  },
};
