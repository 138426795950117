export default {
  namespaced: true,
  state: () => ({
    taskList: [
      {
        task_belong: 'user_action_comment_works',
        reward_type: 'pendant',
        task_desc: '可获得提现券、中秋礼盒等',
        title: '完成任意任务有机会获得',
        button_txt: '领大奖',
        progress_status: 0,
      },
      {
        task_belong: 'user_action_publish_works',
        reward_type: 'coin',
        task_desc: '可获得815金币',
        title: '发布中秋作品得金币',
        button_txt: '领815金币',
        progress_status: 0,
      },
      {
        task_belong: 'user_action_publish_works',
        reward_type: 'coin',
        task_desc: '可获得5000金币',
        title: '发布5个作品得金币',
        button_txt: '领5000金币',
        progress_status: 0,
      },
      {
        task_belong: 'user_action_publish_works',
        reward_type: 'coin',
        task_desc: '可获得10000金币',
        title: '发布10个作品得金币',
        button_txt: '领10000金币',
        progress_status: 0,
      },
    ],
  }),
  mutations: {
    SET_TASK_LIST(state, data) {
      state.taskList = [...data];
    },
  },
  getters: {
    TaskList(state) {
      return state.taskList;
    },
  },
};
