export default {
  namespaced: true,
  state: () => ({
    musicInfo: {},
    videoInfo: {},
    mediaInfo: {},
    music_content_ab: 'a',
    lyricsList: [],
    originLyricsList: '',
    pageTitle: '内容落地页',
    umLink: {},
    quickAppInfo: {},
  }),
  mutations: {
    SET_MUSIC_INFO(state, data) {
      state.musicInfo = data;
    },
    SET_VIDEO_INFO(state, data) {
      state.videoInfo = data;
    },
    SET_MEDIA_INFO(state, data) {
      state.mediaInfo = data;
    },
    SET_MUSIC_CONTENT_AB(state, data) {
      state.music_content_ab = data;
    },
    SET_LYRICS_LIST(state, data) {
      state.lyricsList = data;
    },
    SET_ORIGIN_LYRICS_LIST(state, data) {
      state.originLyricsList = data;
    },
    SET_PAGE_TITLE(state, data) {
      state.pageTitle = data;
    },
    SET_UM_LINK(state, data) {
      state.umLink = data;
    },
    SET_QUICK_APP_INFO(state, data) {
      state.quickAppInfo = data;
    },
  },
  getters: {
    MusicContentAb(state) {
      return state.music_content_ab;
    },
    LyricsList(state) {
      return state.lyricsList;
    },
    PageTitle(state) {
      return state.pageTitle;
    },
    OriginLyricsList(state) {
      return state.originLyricsList;
    },
    UmLink(state) {
      return state.umLink;
    },
    QuickAppInfo(state) {
      return state.quickAppInfo;
    },
  },
};
