export default {
  namespaced: true,
  state: () => ({
    activityInfo: {},
    musicList: {},
    activeTab: 'rule',
    playListId: 0,
    activityId: 0,
    tabMap: {
      rule: '活动规则',
      rank: '排行榜',
      last: '优质投稿',
    },
    activityName: '',
  }),
  mutations: {
    SET_ACTIVITY_INFO(state, data) {
      const { playlist_id, name } = data;

      state.activityInfo = data;
      this.commit('activity/activityTemplate/setPlayListId', playlist_id);
      this.commit('activity/activityTemplate/setActivityName', name);
    },
    SET_MUSIC_LIST(state, data) {
      state.musicList = data;
    },
    setActiveTab(state, data) {
      state.activeTab = data;
    },
    setPlayListId(state, data) {
      if (data !== null && data !== undefined) {
        state.playListId = data;
      }
    },
    setActivityId(state, data) {
      if (data !== null && data !== undefined) {
        state.activityId = data;
      }
    },
    setActivityName(state, data) {
      state.activityName = data;
    },
  },
  getters: {
    ActivityInfo(state) {
      return state.activityInfo;
    },
    MusicList(state) {
      return state.musicList;
    },
    activeTab(state) {
      return state.activeTab;
    },
    playListId(state) {
      return state.playListId;
    },
    activityId(state) {
      return state.activityId;
    },
    tabMap(state) {
      return state.tabMap;
    },
    activityName(state) {
      return state.activityName;
    },
  },
};
