/*
 * @Description: class SDK
 * @FilePath: /kuaiyin-h5/src/sdk/index.js
 */
import {
  ANDROID_SDK_INIT,
  ANDROID_REGISTER,
  ANDROID_APP_CALL,
  ANDROID_LISTEN_METHOD,
  ANDROID_BRIDGE_METHOD,
} from './modules/android';
import { IOS_REGISTER, IOS_APP_CALL, IOS_LISTEN_METHOD, IOS_BRIDGE_METHOD } from './modules/ios';
import platform from '../platform';

class SDK {
  constructor() {
    this.listenMethod = null;
    this.callMethod = null;
    this.register = null;
    this.appCall = null;
    this.$_init();
  }

  /**
   * @description: 初始化方法
   * @return: void
   */

  $_init() {
    const currentPlatform = platform.platform;

    switch (currentPlatform) {
      case 'Android':
        ANDROID_SDK_INIT();
        this.listenMethod = ANDROID_LISTEN_METHOD;
        this.callMethod = ANDROID_BRIDGE_METHOD;
        this.register = ANDROID_REGISTER;
        this.appCall = ANDROID_APP_CALL;
        break;
      case 'iOS':
        this.listenMethod = IOS_LISTEN_METHOD;
        this.callMethod = IOS_BRIDGE_METHOD;
        this.register = IOS_REGISTER;
        this.appCall = IOS_APP_CALL;
        break;
      default:
        ANDROID_SDK_INIT();
        this.listenMethod = ANDROID_LISTEN_METHOD;
        this.callMethod = ANDROID_BRIDGE_METHOD;
        this.register = ANDROID_REGISTER;
        this.appCall = ANDROID_APP_CALL;
    }
  }
}
const sdk = new SDK();

export const appCall = sdk.appCall;
export const callMethod = sdk.callMethod;
export const register = sdk.register;
export const listenMethod = sdk.listenMethod;
export default sdk;
