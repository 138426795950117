const modalType = new Map([]);

export default {
  functional: true,
  name: 'Modal',
  props: {
    type: {
      type: String,
      required: true,
    },
    closeBtnPos: {
      top: Number,
      right: Number,
      /**
       *  value 有四种取值：
       * 'right' (默认)：右上角 30px 距离，大小 0.25 rem;
       * 'left'：左上角 30px 距离，大小 0.25 rem;
       * 'bottom'：底部居中，大小 0.4 rem;
       * 'top'：顶部居中， 距离，大小 0.25 rem;
       */
      value: String | Object,
    },
    childType: Number,
    closeFn: {
      type: Function,
      required: true,
    },
    btnFn: Function,
  },
  render(h, context) {
    const htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.style.overflow = 'hidden';

    const { props, data } = context;

    const closeBtnConf = {
      class: 'close-btn right',
      attrs: {},
      on: {
        click() {
          closeFn();
        },
      },
      domProps: {
        src: require('@/assets/images/common/icon-close.png'),
      },
    };

    if (props.closeBtnPos) {
      const { top, right, value } = props.closeBtnPos;

      if (top && right) {
        closeBtnConf.style = {
          top: `${top}rem`,
          right: `${right}rem`,
        };
      } else if (top) {
        closeBtnConf.style = {
          top: `${top}rem`,
        };
      } else if (right) {
        closeBtnConf.style = {
          right: `${right}rem`,
        };
      } else if (value) {
        closeBtnConf.class = closeBtnConf.class.replace('right', value);
      }
    }

    const closeFn = () => {
      htmlElement.style.cssText = htmlElement.style.cssText.replace(' overflow: hidden;', ''); // 'scroll';
      props.closeFn();
    };

    const btnFn = () => {
      htmlElement.style.cssText = htmlElement.style.cssText.replace(' overflow: hidden;', ''); // 'scroll';

      if (props.btnFn) {
        props.btnFn();
      } else {
        props.closeFn();
      }
    };
    const childProp = {
      handleBtn: btnFn,
      handleClose: closeFn,
      child: props.childType,
      ...data.attrs,
    };

    return h(
      'transition',
      {
        attrs: {
          name: 'modal',
        },
      },
      [
        h(
          'div',
          {
            class: 'modal-wrapper',
          },
          [
            h(
              'main',
              {
                class: 'modal-content',
              },
              [
                h('img', closeBtnConf),
                h(modalType.get(props.type), {
                  props: childProp,
                }),
              ]
            ),
          ]
        ),
      ]
    );
  },
};
