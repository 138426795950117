export default [
  {
    path: '/healthz',
    name: 'Healthz',
    component: () => import('@/views/Common/Healthz/index'),
  },
  {
    path: '/privacy-protocol',
    name: 'PrivacyProtocol',
    component: () => import('@/views/Common/Privacy/PrivacyProtocol'),
  },
  {
    path: '/quick-app/privacy-protocol',
    name: 'QuickAppPrivacyProtocol',
    component: () => import('@/views/Common/Privacy/QuickAppPrivacyProtocol'),
  },
  {
    path: '/privacy-protocol-kyy',
    name: 'PrivacyProtocolKYY',
    component: () => import('@/views/Common/Privacy/PrivacyProtocolKYY'),
  },
  {
    path: '/privacy-protocol-kyfm',
    name: 'PrivacyProtocolKYFM',
    component: () => import('@/views/Common/Privacy/PrivacyProtocolKYFM'),
  },
  {
    path: '/privacy-protocol-iuv',
    name: 'PrivacyProtocolIUV',
    component: () => import('@/views/Common/Privacy/PrivacyProtocolIUV'),
  },
  {
    path: '/privacy-protocol-less',
    name: 'PrivacyProtocolLess',
    component: () => import('@/views/Common/Privacy/PrivacyProtocolLess'),
  },
  {
    path: '/privacy-protocol-demo',
    name: 'PrivacyProtocolDemo',
    component: () => import('@/views/Common/Privacy/PrivacyProtocolDemo'),
  },
  {
    path: '/sdk-privacy-protocol',
    name: 'SDKPrivacyProtocol',
    component: () => import('@/views/Common/Privacy/SDKPrivacyProtocol'),
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: () => import('@/views/Common/Agreement/UserAgreement'),
  },
  {
    path: '/user-agreement-kyy',
    name: 'UserAgreementKYY',
    component: () => import('@/views/Common/Agreement/UserAgreementKYY'),
  },
  {
    path: '/user-agreement-iuv',
    name: 'UserAgreementIUV',
    component: () => import('@/views/Common/Agreement/UserAgreementIUV'),
  },
  {
    path: '/user-agreement-less',
    name: 'UserAgreementLess',
    component: () => import('@/views/Common/Agreement/UserAgreementLess'),
  },
  {
    path: '/user-agreement-demo',
    name: 'UserAgreementDemo',
    component: () => import('@/views/Common/Agreement/UserAgreementDemo'),
  },
  {
    path: '/quick-app/user-agreement',
    name: 'QuickAppUserAgreement',
    component: () => import('@/views/Common/Agreement/QuickAppUserAgreement'),
  },
  {
    path: '/membership-agreement',
    name: 'MemberShipAgreement',
    component: () => import('@/views/Common/Agreement/MemberShipAgreement'),
  },
  {
    path: '/user-release-commitment',
    name: 'UserReleaseCommitment',
    component: () => import('@/views/Common/Policy/UserReleaseCommitment'),
  },
  {
    path: '/user-release-commitment-ba',
    name: 'UserReleaseCommitmentBa',
    component: () => import('@/views/Common/AIMusic/UserReleaseCommitment'),
  },
  {
    path: '/user-release-commitment-kyy',
    name: 'UserReleaseCommitmentKYY',
    component: () => import('@/views/Common/Policy/UserReleaseCommitmentKYY'),
  },
  {
    path: '/child-protection-policy',
    name: 'ChildProtectionPolicy',
    component: () => import('@/views/Common/Policy/ChildProtectionPolicy'),
  },
  {
    path: '/child-protection-policy-kyy',
    name: 'ChildProtectionPolicyKYY',
    component: () => import('@/views/Common/Policy/ChildProtectionPolicyKYY'),
  },
  {
    path: '/child-protection-policy-ba',
    name: 'ChildProtectionPolicyBa',
    component: () => import('@/views/Common/AIMusic/ChildProtectionPolicy'),
  },
  {
    path: '/child-protection-policy-less',
    name: 'ChildProtectionPolicyLess',
    component: () => import('@/views/Common/Policy/ChildProtectionPolicyLess'),
  },
  {
    path: '/quick-app/child-protection-policy',
    name: 'QuickAppChildProtectionPolicy',
    component: () => import('@/views/Common/Policy/QuickAppChildProtectionPolicy'),
  },
  {
    path: '/child-sdk-list',
    name: 'ChildSDKList',
    component: () => import('@/views/Common/Privacy/ChildSDKList'),
  },
  {
    path: '/sdk-list',
    name: 'SDKList',
    component: () => import('@/views/Common/Privacy/SDKList'),
  },
  {
    path: '/sdk-list-kyy',
    name: 'SDKListKYY',
    component: () => import('@/views/Common/Privacy/SDKListKYY'),
  },
  {
    path: '/sdk-list-ba',
    name: 'SDKListBa',
    component: () => import('@/views/Common/AIMusic/SDKList'),
  },
  {
    path: '/sdk-list-iuv',
    name: 'SDKListIUV',
    component: () => import('@/views/Common/Privacy/SDKListIUV'),
  },
  {
    path: '/sdk-list-less',
    name: 'SDKListLess',
    component: () => import('@/views/Common/Privacy/SDKListLess'),
  },
  {
    path: '/ky-protocol',
    name: 'KYProtocol',
    component: () => import('@/views/Common/KYProtocol'),
  },
  {
    path: '/momo-protocol',
    name: 'MoMoProtocol',
    component: () => import('@/views/Common/MoMoProtocol'),
  },
  {
    path: '/withdraw-instructions',
    name: 'WithdrawInstructions',
    component: () => import('@/views/Common/Instructions/WithdrawInstructions'),
  },
  {
    path: '/revenue-instructions',
    name: 'RevenueInstructions',
    component: () => import('@/views/Common/Instructions/RevenueInstructions'),
  },
  {
    path: '/report-process',
    name: 'ReportProcess',
    component: () => import('@/views/Common/ReportProcess'),
  },
  {
    path: '/content-management-rules',
    name: 'ContentManagementRules',
    component: () => import('@/views/Common/ContentManagementRules'),
  },
  {
    path: '/member-refunds',
    name: 'MemberRefunds',
    component: () => import('@/views/Common/Agreement/MemberRefunds'),
  },
  {
    path: '/sgd-authorization-protocol',
    name: 'SGDAuthorizationProtocol',
    component: () => import('@/views/Common/Agreement/SGDAuthorizationProtocol'),
  },
  {
    path: '/moyin-authorization-protocol',
    name: 'MoYinAuthorizationProtocol',
    component: () => import('@/views/Common/Agreement/MoYinAuthorizationProtocol'),
  },
  {
    path: '/xinao-authorization-protocol',
    name: 'XinAoAuthorizationProtocol',
    component: () => import('@/views/Common/Agreement/XinAoAuthorizationProtocol'),
  },
  {
    path: '/longyue-authorization-protocol',
    name: 'LongYueAuthorizationProtocol',
    component: () => import('@/views/Common/Agreement/LongYueAuthorizationProtocol'),
  },
  {
    path: '/songs-authorization-list',
    name: 'SongsAuthorizationList',
    component: () => import('@/views/Common/Agreement/SongsAuthorizationList'),
  },
  {
    path: '/permission-description',
    name: 'PermissionDescription',
    component: () => import('@/views/Common/Privacy/PermissionDescription'),
  },
  {
    path: '/permission-description-kyy',
    name: 'PermissionDescriptionKYY',
    component: () => import('@/views/Common/Privacy/PermissionDescriptionKYY'),
  },
  {
    path: '/permission-description-ba',
    name: 'PermissionDescriptionBa',
    component: () => import('@/views/Common/AIMusic/PermissionDescription'),
  },
  {
    path: '/broadcast-license',
    name: 'BroadcastLicense',
    component: () => import('@/views/Common/Agreement/BroadcastLicense'),
  },
  {
    path: '/third-party-share-list',
    name: 'ThirdPartyShareList',
    component: () => import('@/views/Common/Privacy/ThirdPartyShareList'),
  },
  // 生成备案用 APK 隐私政策
  {
    path: '/ai-music/privacy-protocol',
    name: 'AIMusicPrivacyProtocol',
    component: () => import('@/views/Common/AIMusic/PrivacyProtocol'),
  },
  // 生成备案用 APK 用户协议
  {
    path: '/ai-music/user-agreement',
    name: 'AIMusicUserAgreement',
    component: () => import('@/views/Common/AIMusic/UserAgreement'),
  },
  // 生成备案用 APK 歌手声音生成服务条款
  {
    path: '/ai-music/terms-of-service',
    name: 'AIMusicTermsOfService',
    component: () => import('@/views/Common/AIMusic/TermsOfService'),
  },
  // 生成备案用 APK 第三方信息共享清单
  {
    path: '/ai-music/sdk-list',
    name: 'AIMusicSDKList',
    component: () => import('@/views/Common/AIMusic/SDKList'),
  },
  // 访客模式说明
  {
    path: '/guest-mode-instructions',
    name: 'GuestModeInstructions',
    component: () => import('@/views/Common/Privacy/GuestModeInstructions'),
  },
  // 访客模式说明
  {
    path: '/privacy-policy-summary',
    name: 'PrivacyPolicySummary',
    component: () => import('@/views/Common/Privacy/PrivacyPolicySummary'),
  },
  // 小米金融公司合作信息
  {
    path: '/financial-lending',
    name: 'FinancialLending',
    component: () => import('@/views/Common/FinancialLending'),
  },
];
