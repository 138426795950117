<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import FingerprintJS from '@fingerprintjs/fingerprintjs';

  export default {
    name: 'App',
    components: {},
    props: {},
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    beforeMount() {
      this.sensorsAnalytics();
    },
    mounted() {
      this.landPageLogin();
    },
    methods: {
      /**
       * @description: 神策初始化
       */

      sensorsAnalytics() {
        (async () => {
          const { sa } = window;
          if (sa && sa.track) {
            const userInfo = {};

            const info = {
              platform: userInfo.platform,
              client_v: userInfo['app-v'] || userInfo['client-v'],
              sa_device_id: userInfo.sa_device_id,
              device_id: userInfo['device-id'],
            };

            if (info.sa_device_id) {
              sa.login(info.sa_device_id);
            }

            sa.registerPage({
              uid: userInfo.uid || '',
              platform: info.platform || 'Android',
              app_version: info.client_v || '',
              device_id: info.sa_device_id,
            });
            // this.saRegisterPage();
            sa.quick('autoTrack');
          }
        })();
      },
      /**
       * @description 神策注册页面
       */

      saRegisterPage() {
        // const { sa } = window;
        // if (sa && sa.track) {
        //   // 手动实现referrer， 结合SDK的openpage来使用
        //   let kReferrer = this.$common.getUrlParams('k_referrer');
        //   const href = this.$common.getRmKReferrerHref();
        //
        //   if (kReferrer) {
        //     if (!/^http/.test(kReferrer)) kReferrer = window.location.origin + kReferrer;
        //   }
        //
        //   const rg = {
        //     current_url: decodeURI(href),
        //     referrer: document.referrer ? decodeURI(document.referrer) : kReferrer || '',
        //     time: new Date().getTime(),
        //   };
        //   rg.source = this.$common.getUrlParams('source') || '';
        //
        //   if (this.$route.query.invite_code) {
        //     rg.invite_code = this.$route.query.invite_code;
        //   }
        //
        //   if (this.$route.query.from_type) {
        //     rg.from_type = decodeURI(this.$route.query.from_type);
        //   }
        //   sa.registerPage(rg);
        // }
      },
      landPageLogin() {
        // Get the visitor identifier when you need it.
        const fpPromise = FingerprintJS.load();
        (async () => {
          // Get the visitor identifier when you need it.
          const fp = await fpPromise;
          const result = await fp.get();
          const remarks = {
            visitorId: result.visitorId,
            ip: this.$store.state.clientIP,
            ua: this.$store.state.userAgent,
            path: window.location.pathname,
            referrer: document.referrer ? decodeURI(document.referrer) : '',
          };
          this.$track({ page_title: '落地页', element_name: 'login', remarks: JSON.stringify(remarks) });
        })();
      },
    },
  };
</script>
