export function track(event, data) {
  if (window.sa && window.sa.track) {
    data.time = new Date().getTime();
    data.oaid = '';

    window.sa.track(event, data);
  }
}

export function trackClick(data, event) {
  data = Object.assign({}, data);
  if (data.remarks) data.remarks = String(data.remarks);
  track(event || 'element_click', {
    page_title: data.page_title || '',
    element_type: data.element_type || 'button',
    element_name: data.element_name || '',
    music_user_id: data.music_user_id || '',
    music_id: data.music_id || '',
    music_code: data.music_code || '',
    remarks: data.remarks || '',
  });
}
