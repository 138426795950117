import Vue from 'vue';
import Vuex from 'vuex';
import share from './modules/share';
import activity from './modules/activity';
import app from './modules/app';
import { generateUUID } from '@/utils/common';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: {
      client: {}, // 用户信息
      traceId: null, // 裂变溯源追踪ID
      needLogin: false, // 标记客户端是否需要登录
      needUpdateToken: false, // 标记客户端是否需要更新 AccessToken
      needRedirect: false, // 标记客户端是否需要重定向到 /not-found 公共错误页面
      clientIP: '',
      userAgent: '',
    },
    mutations: {
      setClient(state, data) {
        state.client = { ...data };
      },
      setTraceId(state) {
        state.traceId = generateUUID();
      },
      setAccessToken(state, data) {
        state.client = Object.assign(state.client, data);
      },
      setNeedLogin(state, data) {
        state.needLogin = data;
      },
      setNeedUpdateToken(state, data) {
        state.needUpdateToken = data;
      },
      setNeedRedirect(state, data) {
        state.needRedirect = data;
      },
      setClientIP(state, data) {
        state.clientIP = data;
      },
      setClientUA(state, data) {
        state.userAgent = data;
      },
    },
    actions: {
      getClient({ commit }, data) {
        commit('setClient', data || {});
      },
      updateAccessToken({ commit }, data) {
        commit('setAccessToken', data);
      },
    },
    getters: {
      client: (state) => {
        return state.client;
      },
      traceId: (state) => {
        return state.traceId;
      },
    },
    modules: {
      share,
      activity,
      app,
    },
  });
}
