import shareLanding from './modules/share-landing';
import contentLanding from './modules/content-landing';
import learnMoney from './modules/learn-money';
import doublePunch from './modules/double-punch';
import compositeVideo from './modules/composite-video';
import redirectUrl from './modules/redirect-landing';
import togetherWatch from "./modules/together-watch"

export default {
  namespaced: true,
  state: () => ({}),
  mutation: {},
  actions: {},
  getters: {},
  modules: {
    shareLanding,
    contentLanding,
    learnMoney,
    doublePunch,
    compositeVideo,
    redirectUrl,
    togetherWatch
  },
};
