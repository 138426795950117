export default {
  namespaced: true,
  state: () => ({
    pageInfo: {},
  }),
  mutations: {
    SET_PAGE_INFO(state, data) {
      if (data.user_info.invite_code) {
        data.user_info.invite_code = `邀请码【${data.user_info.invite_code}】`;
      }
      state.pageInfo = data;
    },
  },
  getters: {
    pageInfo(state) {
      return state.pageInfo;
    },
  },
};
