/*
 * @description: 定义安卓客户端调用的接口
 */

const ANDROID_LISTEN_METHOD = {
  ON_SHOW: 'onShow',
  ON_HIDDEN: 'onHidden',
  ON_REWARD_VIDEO_PLAYED: 'onRewardVideoPlayEnd',
  ON_MUSIC_ACTION: 'onMusicAction',
  ON_BIND_WE_CHAT_ACCOUNT: 'onBindWeChatAccount',
  ON_CALENDAR_CALLBACK: 'onCalendarCallBack',
  ON_REWARD_VIDEO_PLAY_FAILED: 'onRewardVideoPlayFailed',
  ON_REWARD_VIDEO_JUMP: 'onRewardVideoJump',
  ON_UPLOAD_SUCCESS: 'onUploadSuccess',
  /**
   * @description: 监听恭喜获得模块相关事件
   * @param {string} event - onCongratulationsPopWindow回调的参数  'show'--弹窗打开 'dismiss'--弹窗关闭 'playVideo'--开始看视频 'playVideoComplete'--看完视频 'playVideoCompleteSkip'--跳过视频
   * @return {void}
   */
  ON_CONGRATULATIONS_POPWINDOW: 'onCongratulationsPopWindow',
  /**
   * 支付完成时间回调
   * @param {string} event - 事件类型，前端自定义，一般使用业务名称
   * @param {string} code - 回调状态码
   */
  ON_PAY_RESULT: 'onPayResult',
  /**
   * 权限申请回调
   * @param {Object}
   *         {
   *           type:'canDrawOverLays',
   *           status: 0 不成功;  1 成功; 2 未知
   *         }
   */
  ON_PERMISSION_SETTING: 'onPermissionSetting',
};

export default ANDROID_LISTEN_METHOD;
