export default {
  namespaced: true,
  state: () => ({
    musicInfo: {},
    userInfo: {},
    roomInfo: {},
    lyricsList: [],
    originLyricsList: [],
    musicName: '',
    invite_code: '',
    musicalInfo: '',
  }),
  mutations: {
    SET_MUSIC_INFO(state, data) {
      state.musicInfo = data;
      this.commit('share/togetherWatch/SET_MUSIC_NAME', data.music_name);
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data;
    },
    SET_ROOM_INFO(state, data) {
      state.roomInfo = data;
    },
    SET_LYRICS_LIST(state, data) {
      state.lyricsList = data;
    },
    SET_ORIGIN_LYRICS_LIST(state, data) {
      state.originLyricsList = data;
    },
    SET_MUSIC_NAME(state, data) {
      state.musicName = data;
    },
    SET_INVITE_CODE(state, data) {
      state.invite_code = data;
    },
    SET_MUSICAL_INFO(state, data) {
      state.musicalInfo = data;
    },
  },
  getters: {
    MusicInfo(state) {
      return state.musicInfo;
    },
    UserInfo(state) {
      return state.userInfo;
    },
    RoomInfo(state) {
      return state.roomInfo;
    },
    LyricsList(state) {
      return state.lyricsList;
    },
    OriginLyricsList(state) {
      return state.originLyricsList;
    },
    MusicName(state) {
      return state.musicName;
    },
    InviteCode(state) {
      return state.invite_code;
    },
    MusicalInfo(state) {
      return state.musicalInfo;
    },
  },
};
